import { STERILIZATION_SPECIFICATIONS_HYDROSECTIONTIMES } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Hydro Section Times", "search-by": "CookerNo,HydroSectionDescription", "ph-search-by": "Search by Cooker, Section" } }, [_c("text-field", { key: "CookerNo", attrs: { "sorter": true, "data-index": "cookerNo", "title": "Cooker", "fixed": "left", "width": 267 } }), _c("text-field", { key: "Chain", attrs: { "sorter": true, "data-index": "chain", "parse": _vm.parseData, "title": "Chain" } }), _c("text-field", { key: "HydroSectionDescription", attrs: { "data-index": "hydroSectionDescription", "title": "Section" } }), _c("text-field", { key: "FlightCount", attrs: { "sorter": true, "data-index": "flightCount", "title": "Flight Count" } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListCookerFlights",
  methods: {
    parseData(val) {
      return val === "O" ? "Outer" : "Inner";
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListCookerFlights = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-cooker-flights" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "page": _vm.page, "name": "sterilization.cooker-flights", "api-url": _vm.apiUrl, "create-route": "/sterilization-specifications/hydro-section-times/create", "edit-route": "/sterilization-specifications/hydro-section-times/:id", "list": _vm.ListCookerFlights } }), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "CookerFlights",
  data() {
    return {
      ListCookerFlights,
      page: STERILIZATION_SPECIFICATIONS_HYDROSECTIONTIMES,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "cookerflights",
          title: "Hydro Section Times",
          path: "/sterilization-specifications/hydro-section-times"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
